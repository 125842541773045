<template>
  <v-dialog
    v-model="dialogOrdemServicoManual"
    @click:outside="$emit('update:dialogOrdemServicoManual', false)"
    @keydown.esc="$emit('update:dialogOrdemServicoManual', false)"
    max-width="1500px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.add") }} {{ $tc("global.ordensServicos") }}
        <v-spacer></v-spacer>

        <v-btn icon @click="dialogPrinter = true">
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('update:dialogOrdemServicoManual', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-tabs v-model="tab" fixed-tabs height="40px">
        <v-tab class="caption">
          {{ $tc("global.dados") }}
        </v-tab>
        <v-tab class="caption" v-if="editar">
          <v-icon small class="mr-1">mdi-image-plus</v-icon>
          {{ $tc("global.midia", 2) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item width="300px">
          <FormOrdemServicoManual
            v-if="chamaForm"
            :ordem_servico="ordem_servico"
            :podeEnviarCaixa.sync="podeEnviarCaixa"
            @podeEnviarCaixa="controlaBotaoCaixa"
            :files.sync="files"
          />
          <v-card v-if="editar" flat>
            <v-card-text class="px-4">
              <ObservacoesGerais
                :origem="'OS_MANUAL'"
                :origem_id="ordem_servico_id"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="editar">
          <InternFileManager
            v-if="tab === 1"
            origem="ORDEM_SERVICO"
            :origem_id="ordem_servico.id"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="button_1"
          @click="dialogOrdemServicoManual = false"
          >{{ $tc("global.fechar") }}</v-btn
        >
        <v-btn
          outlined
          color="button_1"
          @click="openDialogCancelarOs"
          >{{
            $tc("global.cancelar") + " " + $tc("global.ordensServicos")
          }}</v-btn
        >
        <v-btn
          outlined
          color="button_1"
          v-show="podeEnviarCaixa"
          @click="openDialogEnviarCaixaOrdemServicoManual"
          >{{ $tc("global.enviarCaixa") }}</v-btn
        >
        <v-btn color="button_1" class="white--text" @click="postOsManual">{{
          $tc("global.salvar")
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <DialogEnviarCaixaOrdemServicoManual
      v-if="dialogEnviarCaixaOrdemServicoManual"
      :ordem_servico_id="ordem_servico.id"
      @close="closeDialogEnviarCaixaOrdemServicoManual"
      @atualizar="atualizarDepoisDeEnviarProCaixa"
    />
    <DialogCancelarOs
      v-if="dialogCancelarOs"
      :dialogCancelarOs.sync="dialogCancelarOs"
      :ordemServico="ordem_servico"
      @cancelar-os="closeOs"
    />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  filterFiles,
} from "@/api/midias/midias.js";
import { inputRequired } from "@/helpers/utils.js";
import {
  salvarOsManual,
} from "@/api/ordem-servico/ordem_servicos.js";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";

export default {
  name: "DialogOrdemServicoManual",

  props: {
    dialogOrdemServicoManual: {
      type: Boolean,
      default: false,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    ordem_servico_id: {
      type: [Number, String],
    },
  },

  components: {
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
    FormOrdemServicoManual: () => import("./FormOrdemServicoManual.vue"),
    dialogEnviarCaixaOrdemServicoManual: () =>
      import("./DialogEnviarCaixaOrdemServicoManual.vue"),
    ObservacoesGerais: () =>
      import(
        "@/views/dashboard/observacoes-gerais/components/ObservacoesGerais.vue"
      ),
    DialogEnviarCaixaOrdemServicoManual: () =>
      import("./DialogEnviarCaixaOrdemServicoManual.vue"),
    DialogCancelarOs: () => import("./DialogCancelarOs.vue"),
  },

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      files: [],
      ordem_servico: {
        valor: 0,
      },
      tab: 0,
      dialogEnviarCaixaOrdemServicoManual: false,
      dialogCancelarOs: false,
      podeEnviarCaixa: false,
    };
  },

  watch: {},

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      empresa: (state) => state.empresa,
    }),

    chamaForm() {
      if (!this.editar) return true;
      if (this.ordem_servico.id) return true;
      return false;
    },
  },

  methods: {
    controlaBotaoCaixa(value) {
      this.podeEnviarCaixa = value;
    },
    async getFiles() {
      this.loading = true;
      return await filterFiles(`?origem=ORDEM_SERVICO&origem_id=${this.ordem_servico_id}`)
        .then((response) => {
          this.files = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOrdemServico() {
      fetchOrdemServico(this.ordem_servico_id).then((res) => {
        this.ordem_servico = res;
      });
    },
    atualizarDepoisDeEnviarProCaixa() {
      this.getOrdemServico();
      this.$emit("update:dialogOrdemServicoManual", false);
      this.$emit("fetch-ordens-servicos");
    },
    postOsManual() {
      const formData = new FormData();

      for (const key in this.ordem_servico) {
        if (this.ordem_servico[key] != null) {
          formData.append(key, this.ordem_servico[key]);
        } else {
          formData.append(key, "");
        }
      }

      if (this.ordem_servico.produtos){
        for (let i = 0; i < this.ordem_servico.produtos.length; i++) {
          const produto = this.ordem_servico.produtos[i];
          for (const key in produto) {
            if (produto[key] != null) {
              formData.append(`produtos[${i}][${key}]`, produto[key]);
            } else {
              formData.append(`produtos[${i}][${key}]`, "");
            }
          }
        }
      }

      if (this.ordem_servico.prestadores){
        for (let i = 0; i < this.ordem_servico.prestadores.length; i++) {
          const prestador = this.ordem_servico.prestadores[i];
          for (const key in prestador) {
            if (prestador[key] != null) {
              formData.append(`prestadores[${i}][${key}]`, prestador[key]);
            } else {
              formData.append(`prestadores[${i}][${key}]`, "");
            }
          }
        }
      }

      if (this.ordem_servico.files) {
        for (let i = 0; i < this.ordem_servico.files.length; i++) {
          const file = this.ordem_servico.files[i];
          formData.append(`files[${i}]`, file);
        }
      }

      if (this.editar) {
        salvarOsManual(formData, this.ordem_servico_id).then((res) => {
          this.$emit("update:dialogOrdemServicoManual", false);
          this.$emit("fetch-ordens-servicos");
        });
      } else {
        salvarOsManual(formData).then((res) => {
          this.$emit("update:dialogOrdemServicoManual", false);
          this.$emit("fetch-ordens-servicos");
        });
      }
    },

    closeOs() {
      this.$emit("update:dialogOrdemServicoManual", false);
      this.$emit("fetch-ordens-servicos");
    },
    openDialogEnviarCaixaOrdemServicoManual() {
      this.dialogEnviarCaixaOrdemServicoManual = true;
    },
    closeDialogEnviarCaixaOrdemServicoManual() {
      this.dialogEnviarCaixaOrdemServicoManual = false;
    },
    openDialogCancelarOs() {
      this.dialogCancelarOs = true;
    },
  },

  async mounted() {
    if (this.editar) {
      this.getFiles();
      this.getOrdemServico();
    }
  },
};
</script>

<style lang="scss" scoped></style>
